<template>
  <div>
    <div>
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%" src="../../assets/guanghe.png" alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 17px">
          <div style="font-size: 18px">光合城晔光小站</div>
          <div>个性化点单服务</div>
        </div>
      </div>
      <div>
        <van-cell-group>
          <van-field
            v-model="datafrom.Name"
            label="姓名"
            required
            placeholder="请输入姓名"
          />
        </van-cell-group>
        <!-- <van-cell-group>
          <van-field
            v-model="datafrom.sexName"
            @click="sexshow = true"
            readonly="readonly"
            label="性别"
            required
            placeholder="请选择性别"
          />
          <van-popup v-model="sexshow" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="sexlist"
              value-key="Name"
              @cancel="sexshow = false"
              @confirm="onsexshow"
            >
            </van-picker>
          </van-popup>
        </van-cell-group> -->
        <van-cell-group>
          <van-field
            v-model="datafrom.Mobile"
            label="联系电话"
            required
            placeholder="请输入您的联系电话"
          />
        </van-cell-group>
        <van-field
          v-model="datafrom.Address"
          rows="2"
          autosize
          label="地址"
          required
          type="textarea"
          placeholder="请输入您的家庭地址"
        />
        <van-field
          v-model="datafrom.Help"
          rows="2"
          autosize
          required
          label="需求"
          type="textarea"
          placeholder="请输入您需要的帮助"
        />
      </div>
      <div class="buttons">
        <van-button type="info" @click="tijiao()">提 交 心 愿</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { SaveDesireHelp } from "@/api/yeguang";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      datafrom: {
        ID:0,
        Name:"",  //姓名、
        Mobile:"",  //联系电话、
        Address:"",  //地址、
        Help:"",  //心愿、 
        OpenID:"", //、
        Status:1,  //状态（1公示中，2已关闭）、
        Remark:"",  //备注信息、
      },
      sexshow: false,
    };
  },
  created() {},
  methods: {
    // 提交心愿
    tijiao() {
      if (this.datafrom.Name == "") {
        Dialog.alert({
          title: "提示",
          message: "请输入您的姓名",
        }).then(() => {});
        return false;
      }
      if (this.datafrom.sexName == "") {
        Dialog.alert({
          title: "提示",
          message: "请选择您的性别",
        }).then(() => {});
        return false;
      }
      if (this.datafrom.mobel == "") {
        Dialog.alert({
          title: "提示",
          message: "请输入您的手机号",
        }).then(() => {});
        return false;
      }
      if (this.datafrom.address == "") {
        Dialog.alert({
          title: "提示",
          message: "请输入您的地址",
        }).then(() => {});
        return false;
      }
      if (this.datafrom.xuqiu == "") {
        Dialog.alert({
          title: "提示",
          message: "请输入您的心愿需求",
        }).then(() => {});
        return false;
      }
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.OpenID = "jfioshgihjksjkgb";
      SaveDesireHelp(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!请等待反馈。",
          }).then(() => {
            this.$router.push({
              name: "guanghe",
            });
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败," + res.data.msg,
          }).then(() => {});
        }
      });
    },
    // 性别选择器
    onsexshow(val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.buttons {
  text-align: center;
}
.buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
</style>